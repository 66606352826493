import React from "react";
import styled from "styled-components";

const InformationContainer = styled.div`
  position: fixed;

  top: 0;
  right: 0;
  left: 0;
  bottom: 0;

  width: 100%;
  height: 100%;

  background-color: #000;

  z-index: 1000;

  display: ${props => (props.informationVisible ? "flex" : "none")};
  flex-direction: column;
  justify-content: center;
  align-items: center;

  cursor: url(/cursor/close@1x.png) 61 7, pointer;
  cursor: -webkit-image-set(url(/cursor/close@1x.png) 1x, url(/cursor/close@2x.png) 2x) 61 7,
    pointer;
`;

const TextBox = styled.div`
  color: #fff;
  font-size: 100%;

  padding: 18px;
  text-align: center;
`;

const InformationButton = styled.button`
  position: fixed;
  width: 100%;
  left: 0;

  top: ${props => props.top};
  bottom: ${props => props.bottom};

  height: 10%;

  padding: 0;

  z-index: 100;
  cursor: pointer;

  -webkit-appearance: none;
  background-color: transparent;
  border: 0;
  margin: 0;

  color: #fff;

  &:focus {
    outline: none;
  }
`;

const Information = props => (
  <>
    <InformationButton
      onClick={props.onClick}
      top={props.top}
      bottom={props.bottom}
    >
      {props.informationVisible === false && "INFORMATION"}
    </InformationButton>

    <InformationContainer
      informationVisible={props.informationVisible}
      onClick={props.onClick}
    >
      <TextBox
        dangerouslySetInnerHTML={{
          __html: props.text,
        }}
      />
    </InformationContainer>
  </>
);

export default Information;
