import React from "react";
import styled from "styled-components";

const VideoContainer = styled.div`
  width: 100vw;
  height: 100vh;
  max-width: 100vw;

  position: relative;

  overflow: hidden;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  background-color: #000;
`;

const Video = styled.video`
  width: auto;
  height: auto;

  width: 100vw;
  height: 100vh;

  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0);

  object-fit: cover;
  object-position: center;

  overflow: hidden;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
`;

const VideoWithBorder = styled(Video)`
  width: 65vw;
  height: auto;

  @media (max-width: 767px) {
    width: 90vw;
  }
`;

const Preview = props => (
  <VideoContainer>
    {props.video_size === "Full Bleed" ? (
      <Video loop autoPlay muted playsInline preload="none">
        <source type="video/mp4" src={props.video} />
      </Video>
    ) : (
      <VideoWithBorder loop autoPlay muted playsInline preload="none">
        <source type="video/mp4" src={props.video} />
      </VideoWithBorder>
    )}
  </VideoContainer>
);

export default Preview;
