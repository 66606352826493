import { createGlobalStyle } from "styled-components";

const GlobalStyle = createGlobalStyle`

body {
  overflow:hidden;
  will-change: scroll-position;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;

  font-family: "FK Screamer Legacy", "Helvetica-Neue", -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Roboto,  Arial, sans-serif;


  font-size: 40px;
  line-height: 1.5;
  letter-spacing: 0.6px;
  text-transform: uppercase;

  font-weight: normal;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, .004);
  text-rendering: optimizeLegibility !important;
  -webkit-font-smoothing: antialiased !important;
  -webkit-tap-highlight-color: transparent;

  color: #fff;
  background:black;
}

h1,
h2,
h3,
h4 {
  font-weight: normal;
  font-size: 18px;
  margin: 0;
}

video,
img {
  width: 100%;
}

/*--------------------------------------------------------------
  ## Links
  --------------------------------------------------------------*/
a {
  color: #fff;
  text-decoration: none;
}

a:visited {
  color: #fff;
}

a:hover,
a:focus,
a:active {
  color: #fff;
}

a:hover,
a:active,
a:focus {
  outline: 0;
}

/*--------------------------------------------------------------
  ## Floating Cursor
  --------------------------------------------------------------*/

.cursorPosition{
  position: fixed;

  top: 0;
  right: 0;
  left: 0;
  bottom: 0;

  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

/*--------------------------------------------------------------
  ## Media Queries
  --------------------------------------------------------------*/

@media (max-width: 992px) {
  h1,
  h2 {
    font-size: 18px;
  }
}

`;

export default GlobalStyle;
