import React, { useState } from "react";
import styled from "styled-components";
import ReactPlayer from "react-player";
import PlayButton from "../video/mobile-play-button";
import Div100vh from "react-div-100vh";

const VimeoWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  & iframe {
    width: 100vw !important;
    height: 56.25vw !important; /* Given a 16:9 aspect ratio, 9/16*100 = 56.25 */

    min-height: 100vh !important;
    min-width: 177.77vh !important; /* Given a 16:9 aspect ratio, 16/9*100 = 177.77 */

    position: absolute;

    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    border: 0;

    z-index: 100;
  }

  // position: relative;
  // top: 50%;
  // left: 50%;
  // transform: translate(-50%, -50%);

  // z-index: 10;
  pointer-events: none;

  // & .react-player {
  //   position: relative;
  //   overflow: hidden;
  //   padding-top: 56.25%;
  // }

  // & iframe {
  //   position: absolute;
  //   top: 0;
  //   left: 0;
  //   width: 100%;
  //   height: 100%;
  //   border: 0;

  //   z-index: 100;
  // }

  @media (max-width: 768px) {
    pointer-events: all;

    position: relative;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    z-index: 10;

    & .react-player {
      position: relative;
      overflow: hidden;
      padding-top: 56.25%;
    }

    & iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border: 0;

      z-index: 100;
    }
  }
`;

const CloseVideo = styled.div`
  width: 100%;
  height: 100%;

  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  z-index: 1;
`;

const FullVideo = props => {
  const [isPlaying, setIsPlaying] = useState(false);

  return (
    <Div100vh>
      <VimeoWrapper>
        {props.isMobile === true && (
          <PlayButton onClick={() => setIsPlaying(true)} />
        )}

        <ReactPlayer
          id={props.video}
          className="react-player"
          url={props.embedUrl}
          width="100%"
          height="100%"
          playing={props.isMobile === true ? isPlaying : true}
          onEnded={() => setIsPlaying(false)}
          onPause={() => setIsPlaying(false)}
          controls={false}
          volume={1}
          config={{
            vimeo: {
              playerOptions: {
                playsinline: false,
                loop: true,
              },
            },
          }}
        />
      </VimeoWrapper>
      {props.isMobile === true && (
        <CloseVideo onClick={() => props.closeVideo()} />
      )}
    </Div100vh>
  );
};

export default FullVideo;
