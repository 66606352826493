/*
 * Import libraries and frameworks
 */
import React from "react";
import { graphql } from "gatsby";
import styled, { keyframes } from "styled-components";
import { Normalize } from "styled-normalize";
import GlobalStyles from "../styles/globalStyles";
import Div100vh from "react-div-100vh";
import IdleTimer from "react-idle-timer";
import { Helmet } from "react-helmet";

/*
 * custom Components
 */
import Splash from "../splash/splash";
import Project from "../project/project";
import Information from "../information/information";
import Credits from "../project/credits";
import FullVideo from "../video/fullVideo";

const FullVideoContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  width: 100vw;
  height: 100vh;

  background-color: #000;

  cursor: url(/cursor/close@1x.png) 17 17, pointer;
  cursor: -webkit-image-set(url(/cursor/close@1x.png) 1x, url(/cursor/close@2x.png) 2x) 17 17,
    pointer;
`;

const Projects = styled.div`
  position: relative;
  display: inline-block;

  white-space: nowrap;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  overflow: hidden;
  top: 0;
  left: 0;
  background: black;

  cursor: url(/cursor/play@1x.png) 16 16, pointer;
  cursor: -webkit-image-set(url(/cursor/play@1x.png) 1x, url(/cursor/play@2x.png) 2x) 16 16,
    pointer;

  @media (max-width: 480px) {
    display: block;
    width: 100vw;
    height: auto;
    float: none;
  }
`;

const SingleProject = styled.div`
  position: relative;
  display: inline-block;
  width: 100vw;
  height: 100vh;
  margin-left: -2px;

  overflow: hidden;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  transform: translateZ(0);

  background: black;

  @media (max-width: 480px) {
    display: block;
    width: 100vw;
    height: 100vh;
    float: none;
  }
`;

const screensaverAnimation = keyframes`
    0%   { background-color: transparent; }
    49% { background-color: transparent; }
    50% { background-color: #000; }
    99% { background-color: #000; }
    100% { background-color: transparent; }
`;

const Screensaver = styled.div`
  width: 100vw;
  height: 100vh;

  position: fixed;

  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  display: ${props => (props.isVisible ? "block" : "none")};
  z-index: 10000;

  animation: 0.1s ${screensaverAnimation} infinite;
`;

/*
 * Main Index Component – Represents whole page.
 */
class Index extends React.Component {
  constructor() {
    super();
    this.state = {
      opacity: 0,
      informationVisible: false,
      creditsVisible: false,
      currentVideo: null,
      currentVideoCredits: null,
      previousVideo: null,
      embedUrl: null,
      videos: [],
      action: true,
      scroll: 0,
      playing: false,

      limit: [0, 0],
      matrix: [1, 0, 0, 1, 0, 0],
      isMobile: false,
      isScreensaverActive: false,
    };

    this.idleTimer = null;
    this.onActive = this._onActive.bind(this);
    this.onIdle = this._onIdle.bind(this);

    const binder = fcn => fcn.map(f => (this[f] = this[f].bind(this)));

    binder([
      "scrollMove",
      "autoplayScroll",
      "animate",
      "debounce",
      "translate",
      "translateX",
      "translateY",
      "changeDirection",
      "update",
      "scrollLimit",
      "mobileScroll",
      "handleTouchStart",
      "handleTouchMove",
      "getTouchEvt",
      "isMobile",
      "resizeHandler",
    ]);

    this.page = null;

    this.projectsContainer = React.createRef();
    this.lastProject = React.createRef();
    this.timeout = null;

    this.ltr = true; //left to right direction flag
    this.manual = false; // manual flag – block scroll animation, if user is scrolling manually
    this.ticking = false; //debouncing flag for raf – requestAnimationFrame
    this.interval = null; //animation interval
    this.start = null; //debounceTime
    this.touchStart = 0;
    this.touchEnd = 0;
    this.animateTimeout = 0;
    this.scrollSpeed = 0.3;
  }

  _onActive(e) {
    this.setState({
      isScreensaverActive: false,
    });
  }
  _onIdle(e) {
    this.setState({
      isScreensaverActive: true,
    });
  }

  componentDidMount() {
    window.addEventListener("wheel", this.scrollMove);
    window.addEventListener("touchstart", this.handleTouchStart);
    window.addEventListener("touchmove", this.handleTouchMove);
    window.addEventListener("resize", this.resizeHandler);

    let limit;
    this.page = this.projectsContainer.current; //page container reference

    let formattedProjects = this.props.data.prismicHomepage.data.projects.map(
      project => ({
        id: project.project.document[0].data.vimeo_link.video_id,
        data: project.project.document[0].data,
      })
    );
    // Duplicate the first project and add to end of array so it visually loops nicely
    let fp = formattedProjects;
    const firstProject = fp[0];

    fp = [...fp, firstProject];
    this.setState({
      videos: fp,
      limit: limit,
    });

    this.animate();
  }

  getTouchEvt = evt => evt.touches || evt.originalEvent.touches; //browser API || jQuery

  /*
   * Handle mobile init touch event.
   */
  handleTouchStart = evt => {
    this.touchStart = this.getTouchEvt(evt)[0].clientY;
  };

  /*
   * Handle mobile touch move event
   */
  handleTouchMove = evt => {
    this.touchEnd = this.getTouchEvt(evt)[0].clientY;
    setTimeout(
      () => {
        this.touchStart = this.getTouchEvt(evt)[0].clientY;
      },
      200,
      evt
    );
    // document.getElementById("log").innerHTML = `start:${this.touchStart} end:${this.touchEnd}`;
    this.mobileScroll();
  };

  /*
   * debounce callback function. Create timing offset after animation request.
   */
  update = (timestamp, callback, param) => {
    //
    if (!this.start) this.start = timestamp;
    let progress = timestamp - this.start;
    //element.style.transform;  //CALLBACK ACTION
    callback && callback(param || null);
    if (progress < 2000) {
      window.requestAnimationFrame(timestamp =>
        this.update(timestamp, callback, param)
      );
    }
    //
  };

  /*
   * high order debounce funcion, tempate for coupling debounce and css matrix transform into one function
   */
  debounce = callback => {
    return param => {
      requestAnimationFrame(timestamp =>
        this.update(timestamp, callback, param)
      );
      this.ticking = true;
    };
  };

  /*
   * css3 translate matrix function
   */
  translate = matrix => {
    let [a = 0, b = 0, c = 0, d = 0, x = 0, y = 0] = matrix;

    const oldMatrix = this.state.matrix;
    const [oa, ob, oc, od, ox, oy] = oldMatrix;
    const newMatrix = [oa - -a, ob - -b, oc - -c, od - -d, ox - -x, oy - -y];
    let [na, nb, nc, nd, nx, ny] = newMatrix;

    const ds_limit = [
      0,
      -parseInt(window.getComputedStyle(this.page).width) + window.innerWidth,
    ]; // desktop_scroll_limit;
    const ms_limit = [
      0,
      -parseInt(window.getComputedStyle(this.page).height) + window.innerHeight,
    ]; // mobile_scroll_limit;

    nx = this.scrollLimit(nx, ds_limit); //reset or block horizontal slider translate, when hits the limit
    ny = this.scrollLimit(ny, ms_limit);
    const newTransform = `matrix(${na},${nb},${nc},${nd},${nx},${ny})`;
    this.page.style.transform = newTransform;
    this.setState({ matrix: [na, nb, nc, nd, nx, ny] });
    this.manual = false; //reset manual control flag blocking animation
    return newMatrix;
  };

  /*
   * HOF decompose and simplify css matrix with debounce into translateX
   */
  translateX = value => {
    this.debounce(this.translate)([0, 0, 0, 0, value, 0]);
  };

  /*
   * HOF decompose and simplify css matrix with debounce into translateY
   */
  translateY = value => {
    this.debounce(this.translate)([0, 0, 0, 0, 0, value]);
  };

  /*
   * set ltr direction flag based on input value. (Set direction for auto scroll animation)
   */
  changeDirection = value =>
    (this.ltr =
      value < 0 ? true : typeof value === "boolean" && value ? true : false);

  /*
   * Handle scroll event. Scroll horizontaly based on deltaY
   */
  scrollMove = e => {
    this.manual = true;
    let vect = Math.abs(e.deltaX) > Math.abs(e.deltaY)? -e.deltaX : -e.deltaY;
    this.translateX(vect);
    this.changeDirection(vect);
  };

  /*
   * auto scrolling animation, flip flag define direction of animation
   */
  autoplayScroll = (flip = false) => {
    clearInterval(this.interval);
    this.interval = setInterval(() => {
      //animation direction based on previous scroll direction
      let direction = this.ltr ? -1 : 1;
      const translate = !flip ? this.translateX : this.translateY;
      //check if user is scrolling manually, pause animation.
      if (!this.manual) translate(this.scrollSpeed * 10 * direction);
    }, 20);
  };

  /*
   * offset before start scrolling animation (initial screen covers first project)
   */
  animate = _ => this.autoplayScroll(this.isMobile());

  /*
   * reset or block horizontal slider translate, when hits the limit
   */
  scrollLimit = (tx, limit) => {
    const sLimit = limit;
    if (tx > sLimit[0]) {
      return sLimit[1];
    } else if (tx < sLimit[1]) {
      return sLimit[0];
    }
    return tx;
  };

  /*
   * Handle scroll event on mobile device. Scroll vertically
   */
  mobileScroll = evt => {
    const scrollSpeed = this.scrollSpeed;
    if (this.touchStart > this.touchEnd) {
      /* up swipe */
      this.translateY((-this.touchStart + this.touchEnd) * scrollSpeed);
      this.changeDirection(-1);
    } else {
      /* down swipe */
      this.translateY((this.touchEnd - this.touchStart) * scrollSpeed);
      this.changeDirection(1);
    }
  };

  /*
   * Check if userAgent is mobile device. return boolean and store value into state;
   */
  isMobile = _ => {
    if (
      /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|ipad|iris|kindle|Android|Silk|lge |maemo|midp|mmp|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows (ce|phone)|xda|xiino/i.test(
        navigator.userAgent
      ) ||
      /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
        navigator.userAgent.substr(0, 4)
      )
    ) {
      this.setState({ isMobile: true });
      return true;
    }
  };

  /*
   * HOF resize handler decomposed with on resize and debounce
   */
  resizeHandler = () => {
    this.debounce(this.animate)(null);
  };

  openCredits = event => {
    this.setState(
      prevState => ({
        creditsVisible: !prevState.creditsVisible,
      }),
      () => {
        if (this.state.creditsVisible === true) {
          this.timeout = setTimeout(() => {
            this.setState({
              opacity: 1,
            });
          }, 1000);
        } else {
          this.timeout = setTimeout(() => {
            this.setState({
              opacity: 0,
            });
          }, 1000);
        }
      }
    );
  };

  openInformation = event => {
    this.setState(
      prevState => ({
        informationVisible: !prevState.informationVisible,
      }),
      () => {
        if (this.state.informationVisible === true) {
          this.timeout = setTimeout(() => {
            this.setState({
              opacity: 1,
            });
          }, 1000);
        } else {
          this.timeout = setTimeout(() => {
            this.setState({
              opacity: 0,
            });
          }, 1000);
        }
      }
    );
  };

  stopFullVideo(videoId) {
    this.setState({
      currentVideo: null,
      currentVideoCredits: null,
      embedUrl: null,
      playing: false,
    });
  }

  playFullVideo(videoId, embedUrl) {
    let creditsText = this.state.videos.filter(video => video.id === videoId);

    this.setState({
      currentVideo: videoId,
      currentVideoCredits: creditsText[0].data,
      embedUrl: embedUrl,
      playing: true,
    });
  }

  render(props) {
    const data = this.props.data.prismicHomepage.data;
    let projects = this.state.videos;

    const allProjects = projects.map((project, index) => {
      let videoId = project.data.vimeo_link.video_id;
      let embedUrl = project.data.vimeo_link.embed_url;
      return (
        <SingleProject
          key={videoId + index}
          ref={index === projects.length - 1 ? this.lastProject : false}
          onClick={() => this.playFullVideo(videoId, embedUrl)}
        >
          <Project
            details={project.data}
            videoActive={this.state.currentVideo === videoId ? true : false}
          />
        </SingleProject>
      );
    });

    return (
      <>
        <Helmet
          title={`Ruta Balseviciute`}
          meta={[
            {
              name: "description",
              content: `${data.text.text.substring(0, 240)}...`,
            },
            {
              name: "og:description",
              content: `${data.text.text.substring(0, 240)}...`,
            },
            {
              name: "og:locale",
              content: `en`,
            },
            {
              name: "twitter:title",
              content: `Ruta Balseviciute`,
            },
            {
              name: "twitter:description",
              content: `${data.text.text.substring(0, 240)}...`,
            },
            {
              name: "twitter:card",
              content: `summary`,
            },
          ]}
        />
        <Normalize />
        <GlobalStyles />
        <Splash />

        <Screensaver isVisible={this.state.isScreensaverActive} />

        <Projects ref={this.projectsContainer}>{allProjects}</Projects>

        {typeof window !== `undefined` && (
          <IdleTimer
            ref={ref => {
              this.idleTimer = ref;
            }}
            element={document}
            onActive={this.onActive}
            onIdle={this.onIdle}
            debounce={250}
            timeout={90000}
          />
        )}

        {this.state.currentVideo !== null && (
          <Div100vh
            style={{
              position: "absolute",
              height: "100rvh",
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
            }}
          >
            {this.state.isMobile === true ? (
              <>
                <FullVideoContainer>
                  <FullVideo
                    video={this.state.currentVideo}
                    embedUrl={this.state.embedUrl}
                    isMobile={this.state.isMobile}
                    playing={this.state.playing}
                    closeVideo={() =>
                      this.stopFullVideo(this.state.currentVideo)
                    }
                  />
                </FullVideoContainer>

                <Credits
                  credits={this.state.currentVideoCredits}
                  onClick={this.openCredits}
                  creditsVisible={this.state.creditsVisible}
                  opacity={this.state.opacity}
                  isMobile={this.state.isMobile}
                  top={`auto`}
                  bottom={0}
                />
              </>
            ) : (
              <>
                <FullVideoContainer
                  onClick={() => this.stopFullVideo(this.state.currentVideo)}
                >
                  <FullVideo
                    video={this.state.currentVideo}
                    embedUrl={this.state.embedUrl}
                    isMobile={this.state.isMobile}
                    playing={this.state.playing}
                  />
                </FullVideoContainer>

                <Credits
                  credits={this.state.currentVideoCredits}
                  onClick={this.openCredits}
                  creditsVisible={this.state.creditsVisible}
                  opacity={this.state.opacity}
                  isMobile={this.state.isMobile}
                  top={`auto`}
                  bottom={0}
                />
              </>
            )}
          </Div100vh>
        )}

        {this.state.currentVideo === null && (
          <Information
            text={data.text.html}
            onClick={this.openInformation}
            informationVisible={this.state.informationVisible}
            opacity={this.state.opacity}
            top={`auto`}
            bottom={0}
            isMobile={this.state.isMobile}
          />
        )}
      </>
    );
  }
}

export default Index;

export const pageQuery = graphql`
  query Homepage {
    prismicHomepage {
      data {
        text {
          html
          text
        }
        projects {
          project {
            document {
              data {
                video_size
                title {
                  text
                }
                video_preview {
                  url
                }
                vimeo_link {
                  embed_url
                  video_id
                  title
                  width
                  height
                }
                credits {
                  credit_title {
                    text
                  }
                  credit_text
                }
              }
            }
          }
        }
      }
    }
  }
`;
