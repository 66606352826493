import React from "react";
import styled from "styled-components";
import Div100vh from "react-div-100vh";

const SplashContainer = styled.div`
  position: fixed;

  top: 0;
  right: 0;
  left: 0;
  bottom: 0;

  width: 100%;
  height: 100%;

  background-color: #000;

  z-index: 1000;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  opacity: ${props => (props.isVisible ? 1 : 0)};
  transition: 750ms opacity ease;
  display: ${props => (props.hidden ? "none" : "flex")};
`;

const SplashText = styled.h1`
  color: #fff;

  opacity: ${props => (props.opacity ? 1 : 0)};
  transition: 750ms opacity ease;
  text-align:center;
  font-size: 15vmin;
  padding: 0 .5em;
  @media screen and (max-width: 480px) {
  }
`;

class Splash extends React.Component {
  constructor() {
    super();
    this.state = {
      opacity: 1,
      isVisible: true,
      hidden: false,
      isTextVisible: false,
    };
  }

  componentDidMount() {
    setTimeout(() => {
      this.setState({
        isTextVisible: true,
      });
    }, 500);

    setTimeout(() => {
      this.setState({
        isTextVisible: false,
      });
    }, 3500);

    setTimeout(() => {
      this.setState({
        opacity: 0,
        isVisible: false,
      });
    }, 4000);

    setTimeout(() => {
      this.setState({
        hidden: true,
      });
    }, 5000);
  }

  render(props) {
    return (
      <Div100vh
        style={{
          position: "absolute",
          height: "100rvh",
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
        }}
      >
        <SplashContainer
          isVisible={this.state.isVisible}
          hidden={this.state.hidden}
        >
          <SplashText opacity={this.state.isTextVisible}>
            Ruta Balseviciute
          </SplashText>
        </SplashContainer>
      </Div100vh>
    );
  }
}

export default Splash;
