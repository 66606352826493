import React from "react";
import styled from "styled-components";
import Preview from "../video/preview";

const ProjectContainer = styled.div`
  flex: 0 0 100vw;
  height: 100vh;
  width: 100vw;
  max-width: 100vw;

  position: relative;

  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  overflow: hidden;
  transform: translateZ(0);
`;

const Project = props => (
  <ProjectContainer>
    <Preview
      video={props.details.video_preview.url}
      video_size={props.details.video_size}
    />
  </ProjectContainer>
);

export default Project;
