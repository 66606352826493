import React from "react";
import styled from "styled-components";

const CreditsContainer = styled.div`
  position: fixed;

  top: 0;
  right: 0;
  left: 0;
  bottom: 0;

  width: 100%;
  height: 100%;

  background-color: #00000070;

  z-index: 1000;

  display: ${props => (props.creditsVisible ? "flex" : "none")};
  flex-direction: column;
  justify-content: center;
  align-items: center;

  cursor: url(/cursor/close@1x.png) 35 7, pointer;
  cursor: -webkit-image-set(url(/cursor/close@1x.png) 1x, url(/cursor/close@2x.png) 2x) 35 7,
    pointer;
`;

const TextBox = styled.div`
  color: #fff;
  font-size: 40px;

  transition: 500ms opacity ease;

  text-align: center;

  & ul {
    margin: 0;
    padding: 0;
  }

  & li {
    list-style: none;
    margin: 0
    padding: 0;
  }

  & span{
    font-size: 36px;
  }

  & h1 {
    margin-bottom: 1.5em;
    font-size:40px;
  }


  @media(max-width: 767px){
    font-size: 40px;

    & h1,
    & span{
      font-size: 40px;
    }
  }
`;

const CreditsButton = styled.button`
  position: fixed;
  width: 100%;
  left: 0;

  top: ${props => props.top};
  bottom: ${props => props.bottom};

  height: 10%;

  padding: 0;

  z-index: 100;

  -webkit-appearance: none;
  background-color: transparent;
  border: 0;
  margin: 0;

  color: #fff;

  font-size: 100%;

  cursor: pointer;

  &:focus {
    outline: none;
  }
`;

const Credits = props => {
  const creditText = props.credits.credits.map((credit, index) => {
    return (
      <li key={index}>
        <span>{credit.credit_title.text}</span> {credit.credit_text}
      </li>
    );
  });

  return (
    <>
      <CreditsButton
        onClick={props.onClick}
        creditsVisible={props.creditsVisible}
        top={props.top}
        bottom={props.bottom}
      >
        {props.creditsVisible === false && "CREDITS"}
      </CreditsButton>

      <CreditsContainer
        creditsVisible={props.creditsVisible}
        onClick={props.onClick}
      >
        <TextBox opacity={props.opacity}>
          <h1>{props.credits.title.text}</h1>
          <ul>{creditText}</ul>
        </TextBox>
      </CreditsContainer>
    </>
  );
};

export default Credits;
