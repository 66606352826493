import React from "react";
import styled from "styled-components";

const Play = styled.svg``;

const Button = styled.button`
  display: none;

  position: absolute;

  top: 50%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0);

  border: 0;
  -webkit-appearance: none;
  background: transparent;

  width: 50px;

  padding: 5px;
  margin: 0;

  z-index: 1000;

  @media (max-width: 767px) {
    display: block;
  }
`;

const PlayButton = ({ onClick }) => (
  <Button onClick={() => onClick()}>
    <Play
      id="Layer_1"
      dataName="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 37 37"
    >
      <title>PLAY</title>
      <g id="Site">
        <g id="MOBILE-VIDEO-PLAY">
          <g id="download">
            <path
              id="Oval"
              d="M18.5,36A17.5,17.5,0,1,0,1,18.5,17.5,17.5,0,0,0,18.5,36Z"
              fill="none"
              stroke="#fff"
            />
            <polygon
              id="Shape"
              points="14 10 14 26 25.57 18 14 10"
              fill="#fff"
            />
          </g>
        </g>
      </g>
    </Play>
  </Button>
);

export default PlayButton;
